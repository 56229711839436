@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.App {

  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.cursor{
  cursor: pointer;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  padding: 0 !important;
  margin: auto;
  max-width: 100% !important;
}

h4.sub-title{
  max-width: 520px;
  line-height: 30px;
  font-size: 18px;
  color: #36454F;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;

}

.container-section {
  background: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
}

.container-section,
.left-part,
.right-part {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5vh;
}

.left-part,
.right-part {
  width: 50%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}

.left-part {
  padding-right: 80px;
}

h1 {
  font-size: 48px !important;
  margin-bottom: 30px !important;
  width: 100%;
  display: flex;
  font-weight: bolder;
  color: #111826;
}

.left-part img {
  max-width: 100%;
  border-radius: 6px;
  width: 100%;
}

.right-part .custom-file {
  border: 1px dashed #c9c9c9;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  background: #dddddd29;
  font-size: 30px;
  color: #673AB7;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.custom-file.active{
  border-color: #673AB7 !important;
}
.right-part .custom-file:hover {
  background: #92929229;
}

.form-section {
  display: flex;
  gap: 40px;
  font-family: "Quicksand", sans-serif;

  flex-wrap: wrap;
}

.form-section .mb-3 {
  margin-bottom: 20px !important;
}

.form-section img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
}

.mr-20{
  margin-right: 20px;
}

.box-img{  
  /* width: 80px !important; 
  height: 80px !important; */
}

.select-part {
  margin-left: 10px !important;
}

.select-part .col-form-label {
  width: 100%;
}

.custom-select{
  font-family: "Quicksand", sans-serif;

}


.custom-select:focus{
  outline: none !important;
  border-color: #6c62ff !important;
  box-shadow: none !important;
}

.btn-primary {
  padding: 10px 20px !important;
  gap: 15px;
  display: flex !important;
  margin-top: 20px !important;
  background: #6c62ff;
  /* background-image: linear-gradient(to right, #1A2980 0%, #26D0CE 51%, #1A2980 100%); */
  color: white;
  box-shadow: 0 0 20px #eee;
}

.btn-primary:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.btn-grad {
  background: #6c62ff;

  /* background-image: linear-gradient(to right, #f857a6 0%, #ff5858 51%, #f857a6 100%); */
  margin: 10px 0;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border: none;
}

.hidden{
  display: none !important;
}

.custom-font{
  font-family: "Quicksand", sans-serif;

}

.icon {
  gap: 10px;
  font-family: "Quicksand", sans-serif;
  display: flex;
  align-items: center;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


/* Popup styles */
.popup-box {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  position: relative;
  width: 460px;
  padding: 0px;
  background: white;
  border-radius: 10px;
  text-align: center;
  color: #282c34;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 2;

}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.close-icon:hover {
  color: #61dafb;
}

.popup-box img {
  max-width: 100%;
}

.btns-group {
  position: absolute;
  left: 50%;
  bottom: 30px;
  display: flex;
  gap: 10px;
  width: 100%;
  margin-left: -55px;
}

.btns-group p {
  background: #6c62ff;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 100px;
  cursor: pointer;
}

/* Modal */
.modal-content{
  position: relative;
}

.icons-section{
  bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    padding: 12px 24px;
    z-index: 999;
    gap: 30px;
    border-radius: 40px;
    background: #6c62ff;
    margin: auto;
    box-shadow: 0 3px 6px #00000038;
}

.fade-image-container {
  position: relative;
}

.fk{
  width: 26px;
  height: 26px;
}

.amz{
  width: 30px;
  height: 30px;
}
.relative{
  position: relative;
  width: 100%;
}
.select-box select{
  width: 200px;
}

@media (max-width: 1024px) {
  .left-part{
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .left-part, .right-part {
    width: 100%;
  }
  .left-part{
    margin-bottom: 20px;
  }
  .container-section{
    padding: 10px;
  }
  .form-section {
    gap: 20px;
  }
  .container-section,
.left-part,
.right-part {
  padding-top: 20px;
}
.left-part {
  padding-right: 0;
  min-height: 60vh;
}
.icons-section{
  padding: 12px;
  gap: 15px;
}

.left-part img {
  max-width: 100%;
  max-height: 70vh;
 
  object-fit: contain;
}
.left-part img.image{
  min-height: 60vh;
  border: 1px solid #4022de;
}
.amz {
  width: 20px;
  height: 20px;
}
label.form-label{
  font-size: 16px;
  line-height: 18px;
}

}