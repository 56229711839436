.navbar{
    max-width: 100%;
    margin: 0 auto;
    background: #f5f8f9;
    color: #fff;
    padding: 10px 0;
}

.navbar a{
    color: #6c62ff;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    white-space: normal;
    /* background: linear-gradient(90deg, #e871fd 0%, #4022de 57%); */
    background: linear-gradient(90deg, #e871fd 0%, #7c33f6 57%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
}
